import { template as template_584cda8c17c34a20bda62fca6ae359bf } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_584cda8c17c34a20bda62fca6ae359bf(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
