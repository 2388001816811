import { template as template_df2972cc2586480daedbf8c3e7604b2d } from "@ember/template-compiler";
const WelcomeHeader = template_df2972cc2586480daedbf8c3e7604b2d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
