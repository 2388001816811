import { template as template_845ad38e6cb549c094eed04b36c1b82c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_845ad38e6cb549c094eed04b36c1b82c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
