import { template as template_5cdfcda61a104a6eb2e0612f552c452e } from "@ember/template-compiler";
const FKLabel = template_5cdfcda61a104a6eb2e0612f552c452e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
