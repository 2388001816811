import { template as template_25777fce382f4439b8c9658bc16f96c8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_25777fce382f4439b8c9658bc16f96c8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
